



















import { defineComponent, reactive, toRefs } from '@vue/composition-api';

export default defineComponent({
  name: 'HelpTooltipDialogLayout',
  props: ['title'],
  setup() {
    const state = reactive({
      dialog: false,
      showTooltip: true
    });
    const show = () => {
      state.dialog = true;
    };
    return {
      ...toRefs(state),
      show
    };
  }
});
