






































































































































































































































































































import { defineComponent, ref, reactive, toRefs, computed, toRef } from '@vue/composition-api';
import Vue from 'vue';
import { useDomoApi } from '@/module/api/domo';

const { getItems: getDomos } = useDomoApi();

const state = reactive({
  isLoading: false
});

const sourceInfo: any = ref({});
const destinationInfo: any = ref({});

const getSystemSettingsProvisioningInfo = async (domoSetUuid: number) => {
  const provisioningInfo = await getDomos({
    raw: false,
    query: {
      'filter[domoSetUuid]': domoSetUuid,
      'filter[entityType]': 'SYSTEM_SETTINGS'
    }
  });
  return provisioningInfo[0]?.domb.features;
};

export default defineComponent({
  name: 'ImportJobDetailProvisioningComparison',
  props: ['sourceExportJob', 'sourceDomain', 'destinationDomain'],
  setup(props) {
    const loadProvisioningInfo = async () => {
      state.isLoading = true;
      const sourceSystemInfo = await getSystemSettingsProvisioningInfo(
        props.sourceExportJob.domoSetUuid
      );
      const destinationSystemInfo = await getSystemSettingsProvisioningInfo(
        props.destinationDomain.mostRecentExtractJob.domoSetUuid
      );
      const sourceUserInfo = props.sourceDomain.provisioning;
      const destinationUserInfo = props.destinationDomain.provisioning;
      sourceInfo.value = {
        ...sourceSystemInfo,
        ...sourceUserInfo
      };
      destinationInfo.value = {
        ...destinationSystemInfo,
        ...destinationUserInfo
      };
      state.isLoading = false;
    };
    loadProvisioningInfo();
    return {
      ...toRefs(state),
      sourceInfo,
      destinationInfo
    };
  }
});
