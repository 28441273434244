











































































import { defineComponent, ref, reactive, toRefs, computed, unref } from '@vue/composition-api';
import HelpTooltipDialogOperationChanger, {
  showOperationChangerHelpTooltipDialog
} from '@/components/tooltips/HelpTooltipDialogOperationChanger.vue';
import Vue from 'vue';
import {
  OperationModificationActionName,
  OperationModification,
  DomoModifyOperationResponse,
  DomoModifyOperationListItem,
  DomoStateName
} from '@/module/api/domo';
import { useLoadJobApi, LoadJobStateName } from '@/module/api/load-job';
import { createToastInterface } from 'vue-toastification';

const {
  selectedItem: selectedLoadJob,
  selectItem: selectLoadJob,
  getItem: getLoadJob,
  refreshItem: refreshLoadJob,
  modifyOperations,
  getModifyOperations,
  isLoading: isLoadJobLoading
} = useLoadJobApi();

const toast = createToastInterface({ maxToasts: 3 });

const emptyState = () => {
  return {
    loadJobUuid: '', // The LoadJob uuid that is loaded.
    selectedDomoUuids: [] as string[],
    operations: [] as DomoModifyOperationListItem[],
    isSummary: false,
    isLoading: false
  };
};
const state = reactive(emptyState());

const shouldItemsBeLocked = computed(() => {
  Vue.$log.debug(
    `DomoOperationChanger: shouldItemsBeLocked: isSummary=${state.isSummary}, isLoading=${
      state.isLoading
    }, state=${selectedLoadJob.value?.state !== LoadJobStateName.NEW &&
      selectedLoadJob.value?.state !== LoadJobStateName.READY}`
  );
  return (
    state.isSummary ||
    state.isLoading ||
    (selectedLoadJob.value?.state !== LoadJobStateName.NEW &&
      selectedLoadJob.value?.state !== LoadJobStateName.READY)
  );
});

const getOperationItemColor = (operationItem: any): string => {
  if (!operationItem.operationType) {
    return '';
  }
  if (operationItem.operationType === 'NULL') {
    return 'grey';
  }
  if (operationItem.operationType === 'CREATE') {
    return 'green darken-3';
  }
  if (operationItem.operationType === 'UPDATE') {
    return 'blue darken-3';
  }
  if (operationItem.operationType === 'DELETE') {
    return 'red darken-3';
  }
  return '';
};

const getFlatOperationItems = (
  operationItems: DomoModifyOperationListItem[],
  level = 1
): DomoModifyOperationListItem[] => {
  const operations: DomoModifyOperationListItem[] = [];
  for (const operationItem of operationItems) {
    const children = getFlatOperationItems(operationItem.children, level + 1);
    for (const operationItemChild of children) {
      // Add the item but without children.
      operations.push({
        ...operationItemChild,
        children: []
      });
    }
    if (level !== 1) {
      // Add the item but without children.
      operations.push({
        ...operationItem,
        children: []
      });
    }
  }
  return operations;
};

const initializeData = async (jobUuid: string | undefined, forceRefresh = false): Promise<void> => {
  if (!jobUuid) {
    Vue.$log.error(
      'DomoOperationChanger: Cannot initialize data for domoOperationChanger without a load job uuid'
    );
    state.isLoading = false;
    return;
  }

  if (jobUuid !== state.loadJobUuid || forceRefresh) {
    state.isLoading = true;
    const ops = await getModifyOperations(jobUuid);
    state.selectedDomoUuids = ops.selectedItems;
    state.operations = ops.operations;
    state.loadJobUuid = jobUuid;
    state.isLoading = false;
  }
};

const reset = () => {
  Object.assign(state, emptyState());
};

export default defineComponent({
  name: 'DomoOperationChanger',
  components: { HelpTooltipDialogOperationChanger },
  props: {
    jobUuid: {
      type: String
    },
    summary: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchChangeIndex: '1',
    searchText: '',
    searchOp: null,
    selectionType: 'leaf'
  }),
  methods: {
    // Note: we do some magic here because we want our search text AND our
    // select input to both filter the treeview. The treeview doesn't support this.
    filterOpType: function(
      value: DomoModifyOperationListItem,
      search: any,
      searchKey: string
    ): boolean {
      // Uncomment this line to see what the comparison is:
      // Vue.$log.debug(`COMPARE: ${value.operationType} !== ${this.searchOp}`)
      if (this.searchOp && value.operationType !== this.searchOp) {
        return false;
      }
      if (!this.searchText) {
        return true;
      }
      return (
        value != null &&
        value.name
          .toString()
          .toLowerCase()
          .indexOf(this.searchText.toLowerCase()) !== -1
      );
    },
    submitModifyOperations: async function(): Promise<void> {
      if (!this.mappedOperations) {
        const errMessage = 'No operations to skip or unskip. Not submitting to app.';
        Vue.$log.error(errMessage);
        return;
      }
      Vue.$log.debug(
        'Skipping or unskipping operations that have changed...: ',
        this.mappedOperations
      );
      state.isLoading = true;
      const modifyOperationsResponse: DomoModifyOperationResponse = await modifyOperations(
        this.$props.jobUuid,
        {
          operations: this.mappedOperations
        }
      );
      if (!modifyOperationsResponse) {
        const errMessage = 'Failed to modify operations.';
        Vue.$log.error(errMessage);
        toast.error(errMessage);
        state.isLoading = false;
        return;
      }
      toast.success('Updated operations - refreshing tree view.');
      await initializeData(state.loadJobUuid, true);
      state.isLoading = false;
    }
  },
  computed: {
    operationItems(): DomoModifyOperationListItem[] {
      const isFiltered = this.searchText || this.searchOp ? true : false;
      this.selectionType = isFiltered ? 'independent' : 'leaf';
      for (const operation of state.operations) {
        operation.locked = shouldItemsBeLocked.value || isFiltered;
        for (const child of operation.children) {
          child.locked = shouldItemsBeLocked.value;
        }
      }
      return state.operations;
    },
    mappedOperations(): any {
      const flatOperations = getFlatOperationItems(state.operations);
      Vue.$log.debug('OP CHANGER flat ops', flatOperations);

      const returnVal = flatOperations.reduce(
        (
          mappedOperations: any[],
          operationDetails: DomoModifyOperationListItem
        ): OperationModification[] => {
          // It is selected if the treeview model contains it.
          const isSelected = state.selectedDomoUuids.some((selectedUuid: string) => {
            return selectedUuid === operationDetails.id;
          });
          // It was previously selected if its state was NEW.
          const wasSelected = operationDetails.state === DomoStateName.NEW;
          // It has changed if the two are different.
          const hasChanged = wasSelected !== isSelected;
          if (hasChanged) {
            mappedOperations.push({
              uuid: operationDetails.id,
              action: isSelected
                ? OperationModificationActionName.UNSKIP
                : OperationModificationActionName.SKIP
            });
          }
          return mappedOperations;
        },
        []
      );
      // Vue.$log.debug('OP CHANGER flat ops', returnVal);
      return returnVal;
    },
    operationsHaveBeenModified() {
      return Array.isArray(this.mappedOperations) && this.mappedOperations.length > 0;
    }
  },
  watch: {
    summary: function(newVal: boolean, oldVal: boolean): void {
      Vue.$log.debug(`DomoOperationChanger: changed summary from ${oldVal} to ${newVal}`);
      state.isSummary = newVal === true ? true : false;
    },
    searchText: function(newVal: any, oldVal: any): void {
      const newChangeIndex = this.searchChangeIndex === '2' ? '1' : '2';
      Vue.$log.debug(
        `DomoOperationChanger: searchText changed search change index from ${this.searchChangeIndex} to ${newChangeIndex}`
      );
      this.searchChangeIndex = newChangeIndex;
    },
    searchOp: function(newVal: any, oldVal: any): void {
      const newChangeIndex = this.searchChangeIndex === '2' ? '1' : '2';
      Vue.$log.debug(
        `DomoOperationChanger: searchOp changed search change index from ${this.searchChangeIndex} to ${newChangeIndex}`
      );
      this.searchChangeIndex = newChangeIndex;
      // We must use null to get the element to detect no value is set, because clearable.
      if (!newVal) {
        this.searchOp = null;
      }
    }
  },
  emits: ['load:start', 'load:stop', 'save:items'],
  setup(props, context) {
    Vue.$log.debug(`DomoOperationChanger: props are ${JSON.stringify(props)}`);
    if (selectedLoadJob.value?.uuid !== props.jobUuid) {
      Vue.$log.error(
        `DomoOperationChanger: The load job ${props.jobUuid} must have already been selected!`
      );
      return;
    }
    if (props.jobUuid !== state.loadJobUuid) {
      Vue.$log.debug(
        `DomoOperationChanger: Resetting op changer because jobUuid is not the selected job`
      );
      reset();
    }
    state.isSummary = props.summary === true ? true : false;
    Vue.$log.debug(
      `DomoOperationChanger: Loading DomoOperationChanger as a ${
        state.isSummary ? 'summary' : 'not-summary'
      } (prop was ${typeof props.summary}:${props.summary})`
    );

    context.emit('load:start');
    initializeData(props.jobUuid).then(() => {
      context.emit('load:stop');
    });

    return {
      LoadJobStateName,
      ...toRefs(state),
      getOperationItemColor,
      showOperationChangerHelpTooltipDialog,
      selectedLoadJob,
      isLoadJobLoading,
      shouldItemsBeLocked,
      isLoading: computed(() => state.isLoading)
    };
  }
});
