var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("help-tooltip-dialog-layout", {
    key: "help",
    ref: "helpTooltipDialog",
    attrs: { title: "Operation Changer" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "div",
              [
                _c("v-card-text", { staticClass: "h2-pa-12 mt-4" }, [
                  _vm._v(
                    "The operation changer shows operations that will execute when this job is run. You can uncheck or check operations to either ignore them or un-ignore them. If an operation is checked, it will be run. If an operation is unchecked, it will be ignored."
                  )
                ]),
                _c("v-card-text", { staticClass: "h2-pa-12 mt-2" }, [
                  _vm._v(
                    "Example: You have 10 users listed in the tree view. You unselect all of the users, and hit save. In this case, those 10 users will be ignored by the app."
                  )
                ]),
                _c(
                  "v-card-text",
                  [
                    _c("v-icon", [_vm._v("mdi-menu-right")]),
                    _c("v-icon", { staticClass: "mx-6" }, [
                      _vm._v("mdi-arrow-right")
                    ]),
                    _vm._v(
                      " Expand entity type to list operations for that entity. I.E. expand to see all user operations. "
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "elevation-2",
                        attrs: {
                          border: "top",
                          "colored-border": "",
                          type: "info"
                        }
                      },
                      [_vm._v(" Tip: You must hit save to commit any changes")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }