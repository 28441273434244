var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c("v-dialog", {
            attrs: { transition: "dialog-top-transition", "max-width": "600" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(dialog) {
                    return [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { dark: "", dense: "" } },
                            [
                              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))])
                            ],
                            1
                          ),
                          _vm._t("content", function() {
                            return [_c("div", [_vm._v("Tooltip Content")])]
                          }),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      dialog.value = false
                                    }
                                  }
                                },
                                [_vm._v("Close")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.dialog,
              callback: function($$v) {
                _vm.dialog = $$v
              },
              expression: "dialog"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }