var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", isLoading: _vm.isLoading } },
                    [
                      _c("v-card-title", [_vm._v("Source")]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Inbound Campaigns: " +
                                      _vm._s(_vm.sourceInfo.inboundCampaigns)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Outbound Campaigns: " +
                                      _vm._s(_vm.sourceInfo.outboundCampaigns)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Inbound Voice Lines: " +
                                      _vm._s(_vm.sourceInfo.inboundVoiceLines)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Outbound Voice Lines: " +
                                      _vm._s(_vm.sourceInfo.outboundVoiceLines)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Skill Groups: " +
                                      _vm._s(_vm.sourceInfo.skillGroups)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Call Variables: " +
                                      _vm._s(_vm.sourceInfo.callVariables)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Call Variables: " +
                                      _vm._s(_vm.sourceInfo.callVariables)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Named Agents: " +
                                      _vm._s(_vm.sourceInfo.namedAgents)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total CRM Variables: " +
                                      _vm._s(_vm.sourceInfo.crmVariables)
                                  )
                                ])
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "E164 Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.sourceInfo
                                          .isInternationalNumberFormat,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "isInternationalNumberFormat",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sourceInfo.isInternationalNumberFormat"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "TCPA Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.sourceInfo.tcpaModeEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "tcpaModeEnabled",
                                          $$v
                                        )
                                      },
                                      expression: "sourceInfo.tcpaModeEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Visual IVR Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.sourceInfo.visualIvrEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "visualIvrEnabled",
                                          $$v
                                        )
                                      },
                                      expression: "sourceInfo.visualIvrEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Social Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.sourceInfo.soCoCareSocialEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "soCoCareSocialEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sourceInfo.soCoCareSocialEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Chats Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.sourceInfo.soCoCareChatsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "soCoCareChatsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sourceInfo.soCoCareChatsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Emails Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.sourceInfo.soCoCareEmailsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "soCoCareEmailsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sourceInfo.soCoCareEmailsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Connectors Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.sourceInfo.connectorsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "connectorsEnabled",
                                          $$v
                                        )
                                      },
                                      expression: "sourceInfo.connectorsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Advanced Connectors Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.sourceInfo
                                          .advancedConnectorsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "advancedConnectorsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sourceInfo.advancedConnectorsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "External Routing Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.sourceInfo.externalRoutingEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceInfo,
                                          "externalRoutingEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sourceInfo.externalRoutingEnabled"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", isLoading: _vm.isLoading } },
                    [
                      _c("v-card-title", [_vm._v("Destination")]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Inbound Campaigns: " +
                                      _vm._s(
                                        _vm.destinationInfo.inboundCampaigns
                                      )
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Outbound Campaigns: " +
                                      _vm._s(
                                        _vm.destinationInfo.outboundCampaigns
                                      )
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Inbound Voice Lines: " +
                                      _vm._s(
                                        _vm.destinationInfo.inboundVoiceLines
                                      )
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Outbound Voice Lines: " +
                                      _vm._s(
                                        _vm.destinationInfo.outboundVoiceLines
                                      )
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Skill Groups: " +
                                      _vm._s(_vm.destinationInfo.skillGroups)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Call Variables: " +
                                      _vm._s(_vm.destinationInfo.callVariables)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Call Variables: " +
                                      _vm._s(_vm.destinationInfo.callVariables)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total Named Agents: " +
                                      _vm._s(_vm.destinationInfo.namedAgents)
                                  )
                                ])
                              ]),
                              _c("v-row", [
                                _c("p", [
                                  _vm._v(
                                    "Total CRM Variables: " +
                                      _vm._s(_vm.destinationInfo.crmVariables)
                                  )
                                ])
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "E164 Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo
                                          .isInternationalNumberFormat,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "isInternationalNumberFormat",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.isInternationalNumberFormat"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "TCPA Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo.tcpaModeEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "tcpaModeEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.tcpaModeEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Visual IVR Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo.visualIvrEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "visualIvrEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.visualIvrEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Social Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo
                                          .soCoCareSocialEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "soCoCareSocialEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.soCoCareSocialEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Chats Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo
                                          .soCoCareChatsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "soCoCareChatsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.soCoCareChatsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Emails Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo
                                          .soCoCareEmailsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "soCoCareEmailsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.soCoCareEmailsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Connectors Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo.connectorsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "connectorsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.connectorsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Advanced Connectors Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo
                                          .advancedConnectorsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "advancedConnectorsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.advancedConnectorsEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "External Routing Enabled",
                                      type: "boolean",
                                      required: "",
                                      readonly: "",
                                      disabled: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.destinationInfo
                                          .externalRoutingEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.destinationInfo,
                                          "externalRoutingEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "destinationInfo.externalRoutingEnabled"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }